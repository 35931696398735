<template>
  <div class="talentsData">
    <div class="topCard" style="margin-bottom: 20px">
      <el-card>
        <!-- 按字段查询 -->
        <el-form label-width="90px" class="ly-header__bg" style="margin: 0">
          <el-form-item label="所在地区：">
            <cascader-area
              :country="queryInfo.personCountry"
              :province="queryInfo.personProvince"
              :city="queryInfo.personCity"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </el-form-item>
          <el-form-item label="人才类型：">
            <dic-radio-button
              :code.sync="queryInfo.personType"
              type-code="004"
              query-name="personType"
              name="人才类型"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="合作状态：">
            <dic-radio-button
              :code.sync="queryInfo.cooperationStatus"
              type-code="001"
              query-name="cooperationStatus"
              name="合作状态"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="工作经验：">
            <dic-radio-button
              :code.sync="queryInfo.workingYears"
              type-code="005"
              query-name="workingYears"
              name="工作经验"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="技术类别：">
            <dic-tag-button
              :code.sync="queryInfo.techIdName"
              query-name="techIdName"
              name="技术类别"
              tag="技术"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="职能类型：">
            <dic-radio-button
              :code.sync="queryInfo.positionType"
              type-code="006"
              query-name="positionType"
              name="职能类型"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="领域类别：">
            <dic-tag-button
              :code.sync="queryInfo.fieldIdName"
              query-name="fieldIdName"
              name="领域类别"
              tag="领域"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="筛选条件：" class="filterInfo">
            <el-tag
              closable
              v-for="(item, index) in tags"
              :key="index + item.name"
              @close="closeTag(index, item.tagName)"
            >
              {{ item.name }}：{{
                queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
              }}
              <span
                class="circle"
                v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
                >+{{ item.valLength }}</span
              >
            </el-tag>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加人才 -->
          <el-popover
            placement="bottom-start"
            width="150"
            trigger="click"
            visible-arrow="false"
          >
            <div style="text-align: center">
              <el-dropdown-item>
                <p style="color: #606266" @click="additem">学术机构人才</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p style="color: #606266" @click="additem1">企业机构人才</p>
              </el-dropdown-item>
            </div>
            <el-button slot="reference" type="primary" icon="jr-iconjr-icon-add"
              >添加</el-button
            >
          </el-popover>
          <!-- 上传、删除 -->
          <!-- <upload-file-pro></upload-file-pro> -->
          <!-- <upload-file-talent></upload-file-talent> -->
          <el-button @click="dialogVisibleTalent1 = true" style="margin: 0px"
            >头像上传<i class="el-icon-picture-outline-round"></i
          ></el-button>
          <el-button @click="dialogVisibleTalent = true"
            >批量上传<i class="el-icon-upload2"></i
          ></el-button>
          <el-button @click="multDelete" style="margin: 0px">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="请输入姓名"
          v-model="queryInfo.name"
          class="lager-input"
          @change="getTalent"
        >
          <template slot="append">
            <div @click="getTalent" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :cell-style="set_cell_style"
        @selection-change="handleSelectionChange"
        @cell-dblclick="tableDoubleClick"
        @sort-change="sortChange"
        center
        style="width: 100%"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="personCode"
          label="序号"
          align="center"
          width="70"
        >
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="headPortraits"
          label="头像"
          align="center"
          width="100"
        >
          <template v-slot="{ row }">
            <div
              @click="editTalent(row)"
              style="cursor: pointer"
              v-if="row.headPortraits"
            >
              <el-image :src="row.headPortraits" class="headerImg" />
            </div>
            <div @click="editTalent(row)" style="cursor: pointer" v-else>
              <div v-if="row.sex == '男'">
                <el-image
                  :src="require('../../assets/img/male.png')"
                  class="headerImg"
                />
              </div>
              <div v-else>
                <el-image
                  :src="require('../../assets/img/female.png')"
                  class="headerImg"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="personDataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
        >
          <template v-slot="{ row }">
            <div @click="editTalent(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.personDataIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center" width="80">
          <template v-slot="{ row }">
            <div @click="editTalent(row)" style="cursor: pointer">
              <span>{{ row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyName,currentCompany"
          label="单位名称"
          align="center"
        >
          <template v-slot="{ row }">
            <!-- <span v-if="row.personType=='企业机构人才'">{{row.companyName}}</span> -->
            <span>{{ row.currentCompany }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="position"
          label="职位"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="fieldId"
          label="领域类别"
          style="color: blue"
          width="150"
          align="center"
        >
          <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.fieldId }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="scope.row.fieldId !== null && scope.row.fieldId !== ''"
              class="tableColor"
            >
              {{ scope.row.fieldId.substring(0, 25) }}
              <span v-if="scope.row.fieldId.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="workingYears"
          label="工作经验"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="positionType"
          label="职能类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="personCountry,personProvince,personCity"
          label="所在地区"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.personCountry ? scope.row.personCountry : "" }}
            {{ scope.row.personProvince ? scope.row.personProvince : "" }}
            {{ scope.row.personCity ? scope.row.personCity : "" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.personCode)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 批量删除弹出框 -->
    <el-dialog
      title="批量上传"
      :visible.sync="dialogVisibleTalent"
      width="70%"
      @close="handleCloseTalent"
      center
      v-loading="loading"
      element-loading-text="正在上传中，请稍后"
      element-loading-spinner="el-icon-loading"
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="22">
          <upload-file-talent
            @filesDo="filesDo"
            ref="uploadTalent"
          ></upload-file-talent>
        </el-col>
      </el-row>
      <el-table
        :data="dataData17"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
        style="overflow: auto"
        height="350"
        center
      >
        <el-table-column
          type="index"
          width="200"
          :label="'序号' + '(总数为' + dataData17.length + ')'"
        >
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column prop="name" label="文件名(上传文件最大限制为100)">
        </el-table-column>
        <el-table-column prop="size" label="大小">
          <template slot-scope="scope">
            <span>{{ (scope.row.size / 1024 / 1024).toFixed(2) + "MB" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0; color: red"
              @click="deleteTalentsData(scope.row, scope.$index)"
            >
              <i class="jr-iconjr-icon-delect" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadTalents">确 定</el-button>
        <el-button @click="dialogVisibleTalent = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="头像上传"
      :visible.sync="dialogVisibleTalent1"
      width="70%"
      @close="handleCloseTalent1"
      center
      v-loading="loading"
      element-loading-text="正在上传中，请稍后"
      element-loading-spinner="el-icon-loading"
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="22">
          <upload-file-pro
            @filesPro="filesPro"
            ref="uploadTalent1"
          ></upload-file-pro>
        </el-col>
      </el-row>
      <el-table
        :data="dataData18"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
        style="overflow: auto"
        height="350"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          width="200"
          :label="'序号' + '(总数为' + dataData18.length + ')'"
        >
        </el-table-column>
        <el-table-column prop="name" label="文件名(上传文件最大限制为100)">
        </el-table-column>
        <el-table-column prop="size" label="大小">
          <template slot-scope="scope">
            <span>{{ (scope.row.size / 1024 / 1024).toFixed(2) + "MB" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0; color: red"
              @click="deleteTalentsData1(scope.row, scope.$index)"
            >
              <i class="jr-iconjr-icon-delect" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadTalents1">确 定</el-button>
        <el-button @click="dialogVisibleTalent1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogType"
      :visible.sync="dialogVisible2"
      width="60%"
      @close="handleClose"
      center
    >
      <el-form :model="editTableObj" label-width="100px">
        <el-form-item label="技术类别：" v-if="dialogType === '技术类别'">
          <dic-checkbox-group
            ref="checkboxEnterprise"
            :code.sync="editTableObj.currentTechId"
            tag="技术"
            key="技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域类别：" v-else>
          <dic-checkbox-group
            ref="checkboxEnterprise"
            :code.sync="editTableObj.fieldId"
            tag="领域"
            key="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveItem">保 存</el-button>
        <el-button @click="dialogVisible2 = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from "@/components/DicTagButton.vue";
import { getCityAll } from "@/api/common";
import UploadFileTalent from "@/components/UploadFileTalent.vue";
import UploadFilePro from "@/components/UploadFilePro.vue";
import ButtonTable from "../../components/ButtonTable.vue";
import {
  getTalents,
  deleteTalentData,
  updateTalents,
  uploadImportTalent,
  uploadImportTalentPro,
} from "@/api/talent.js";
import CascaderArea from "@/components/CascaderArea.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { Divider } from "element-ui";
export default {
  components: {
    ButtonTable,
    CascaderArea,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    UploadFileTalent,
    UploadFilePro,
  },
  created() {
    this.getTalent();
    this.serachArea();
  },
  data() {
    return {
      dialogVisibleTalent: false,
      dialogVisibleTalent1: false,
      valueI: 0,
      tags: [
        {
          name: "合作状态",
          tagName: "cooperationStatus",
        },
        {
          name: "人才类型",
          tagName: "personType",
        },
        {
          name: "技术类别",
          tagName: "techIdName",
          valLength: 0,
        },
        {
          name: "领域类别",
          tagName: "fieldIdName",
          valLength: 0,
        },
        {
          name: "工作经验",
          tagName: "workingYears",
        },
        {
          name: "职能类型",
          tagName: "positionType",
        },
      ],
      progressFlag: false, //进度条初始值隐藏
      progressPercent: 0, //进度条初始值
      editTableObj: {
        currentTechId: [], // 技术类别
        fieldChildrenId: [], // 领域类别二级标签
        fieldId: [], // 领域类别
        // fieldChildrenId: [], // 技术类别二级标签
      },
      dialogType: "领域类别",
      list: {
        countrys: [],
        provinces: [],
        citys: [],
      },
      // 弹出框参数
      dialogVisible: false,
      dialogVisible2: false,
      // 所在城市
      addboolean: false,
      // 表格数据
      queryInfo: {
        name: "",
        pageSize: 10,
        pageNum: 1,
        order: 1,
        columnName: "create_time,update_time",
        currentTechId: [],
        fieldId: [],
      },
      dataData: [],
      dataData18: [],
      total: 0,
      // 搜索
      input2: "",
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
      dataData17: [],
      errorMes: [],
      loading: false,
    };
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "create_time,update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.getTalent();
    },
    // 浏览人才数据
    getTalent1() {
      let name = this.queryInfo.name;
      this.queryInfo = {
        name: name,
        columnName: "create_time,update_time",
        pageSize: 10,
        pageNum: 1,
        order: 1,
      };
      this.getTalent();
    },
    async getTalent() {
      const res = await getTalents(this.queryInfo);
      if (res.code === 200) {
        this.dataData = res.data.list;
        this.total = res.data.total;
        this.dataData.forEach((el) => {
          if (el.personDataIntegrity) {
            if (String(el.personDataIntegrity).includes(".")) {
              el.personDataIntegrity = Number(
                el.personDataIntegrity.slice(0, -1)
              );
            } else {
              el.personDataIntegrity = Number(el.personDataIntegrity);
            }
          } else {
            el.personDataIntegrity = 0;
          }
        });
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getTalent();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getTalent();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.personCode;
      });
    },
    // 跳转编辑页面
    editTalent(data) {
      let queryData = JSON.stringify(data);
      if (data.personType == "企业机构人才") {
        // this.$router.push({
        //   name: "editTalentQY",
        //   params: {
        //     personData: data,
        //     personType: 1,
        //   },
        // });
        let text = this.$router.resolve({
          path: "/editTalentQY",
          query: {
            personData: queryData,
            personType: 1,
          },
        });
        window.open(text.href, "_blank");
      } else {
        // this.$router.push({
        //   name: "editTalentXS",
        //   params: {
        //     personData: data,
        //     personType: 1,
        //   },
        // });
        let text = this.$router.resolve({
          path: "/editTalentXS",
          query: {
            personData: queryData,
            personType: 1,
          },
        });
        window.open(text.href, "_blank");
      }
    },
    // 单个删除
    deleteTalent(id) {
      this.deleteTalentData[0] = id;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          const res = await deleteTalentData(this.deleteTalentData);
          if (res.code == 200) {
            this.getTalent();
            this.deleteTalentData = [];
          }
        }
      );
    },
    // 批量删除
    multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm("此操作将永久删除批量文件, 是否继续?", "提示").then(
          async () => {
            const res = await deleteTalentData(this.multipleSelection);
            if (res.code == 200) {
              this.getTalent();
              this.multipleSelection = [];
            }
          }
        );
      } else {
        this.$message.error("批量删除必须大于一条");
      }
    },
    // 批量上传
    handleClose() {
      this.$refs.checkboxEnterprise.clear();
    },
    async serachArea() {
      const { data: res } = await getCityAll();

      this.list.countrys = res;
    },
    // 跳转新增学术人才
    additem() {
      let queryData = JSON.stringify({ person: "111" });
      this.$router.push({
        name: "editTalentXS",
        query: {
          personData: queryData,
        },
      });
    },
    // 跳转新增企业人才
    additem1() {
      let queryData = JSON.stringify({ person: "111" });
      this.$router.push({
        name: "editTalentQY",
        query: {
          personData: queryData,
        },
      });
    },

    // 正序
    caretTop() {
      this.queryInfo.order = 1;
      this.getTalent();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 0;
      this.getTalent();
    },
    // 所在地区
    searchCountry(val) {
      this.queryInfo.personCountry = val;
      this.getTalent();
    },
    searchProvince(val) {
      this.queryInfo.personProvince = val;
      this.getTalent();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.personCity = val;
        this.getTalent();
      }
    },

    // 双击单元格
    tableDoubleClick(row, column) {
      if (column.label === "领域类别") {
        Object.assign(this.editTableObj, row);

        if (this.editTableObj.fieldId) {
          this.editTableObj.fieldId = this.formatString(
            this.editTableObj.fieldId
          );
        } else {
          this.editTableObj.fieldId = [];
        }

        this.dialogVisible2 = true;

        if (this.$refs.checkboxEnterprise) {
          this.$refs.checkboxEnterprise.getDicData();
        }
      }
    },
    updateCode(val, tag) {
      this.$nextTick(() => {
        switch (tag) {
          case "领域":
            this.editTableObj.fieldChildrenId = val;
            break;

          case "技术":
            this.editTableObj.techChildrenId = val;
            break;

          default:
            break;
        }
      });
    },
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.fieldId = this.editTableObj.fieldId.concat(
          this.editTableObj.fieldChildrenId
        );
      }
      this.editTableObj.fieldId = [...new Set(this.editTableObj.fieldId)];
      if (this.editTableObj.currentTechId) {
        this.editTableObj.currentTechId =
          this.editTableObj.currentTechId.split(",");
      }
      if (this.editTableObj.expectedTechId) {
        this.editTableObj.expectedTechId =
          this.editTableObj.expectedTechId.split(",");
      }
      if (this.editTableObj.positionType) {
        this.editTableObj.positionType =
          this.editTableObj.positionType.split(",");
      }
      // var params = { personDto: this.editTableObj }
      const res = await updateTalents(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible2 = false;

      this.getTalent();
    },
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "技术类别" || name === "领域类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "技术类别":
          this.queryInfo.currentTechId = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.currentTechId =
              this.queryInfo.currentTechId.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.fieldId = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.fieldId = this.queryInfo.fieldId.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }
      this.getTalent();
    },
    closeTag(index, name) {
      this.tags.splice(index, 1);
      switch (name) {
        case "fieldIdName":
          this.queryInfo.fieldIdName = "";
          this.queryInfo.fieldId = [];
          break;
        case "techIdName":
          this.queryInfo.techIdName = "";
          this.queryInfo.currentTechId = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.getTalent();
    },

    // 批量上传
    filesDo(data) {
      let FileExt = data.map((item) => {
        return item.name.replace(/.+\./, "");
      });
      let flag = FileExt.map((item) => {
        return ["xls", "xlsx"].includes(item);
      });
      let errorMe = [];
      flag.forEach((item, index) => {
        if (!item) {
          errorMe.push(index);
        }
      });
      this.dataData17 = data;
      this.errorMes = errorMe.map((item) => {
        return (item = item + 1);
      });
    },
    deleteTalentsData(data, index) {
      this.dataData17.splice(index, 1);
      for (let i = 0; i < this.errorMes.length; i++) {
        if (this.errorMes[i] == index + 1) {
          for (let j = 0; j < this.errorMes.length; j++) {
            if (this.errorMes[j] > this.errorMes[i]) {
              this.errorMes[j] = this.errorMes[j] - 1;
            }
          }
          this.errorMes.splice(i, 1);
        }
      }
    },
    async uploadTalents() {
      if (this.errorMes.length > 0) {
        const errorMes1 = this.errorMes.join(",");
        this.$confirm(
          "第" + errorMes1.substring(0, 300) + "条数据格式错误",
          "提示",
          {
            dangerouslyUseHTMLString: true,
            customClass: "message-logout2",
          }
        );
      } else {
        if (this.dataData17.length <= 100) {
          const form = new FormData();
          this.dataData17.forEach((item, index) => {
            form.append("files", this.dataData17[index]);
          });
          this.loading = true;
          const res = await uploadImportTalent(form);
          if (res.errorMsg) {
            const errorData = res.errorMsg.join(",");
            this.$confirm(errorData, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout2",
            });
            this.loading = false;
          }
          if (res.code == 500) {
            this.loading = false;
            this.$message.error(res.msg);
          }
          // if(!res.errorMsg.length>0){
          //   this.$message.success("数据全部上传成功")
          //   this.loading = false
          // }else{
          //   this.$confirm(res.errorMsg, "提示")
          //     .then(() => {
          //     })
          //     .catch(() => {});
          //   this.loading = false
          // }
          this.getTalent();
          this.dialogVisibleTalent = false;
        } else {
          this.$message.error("上传文件个数小于100");
        }
      }
    },
    handleCloseTalent() {
      this.$refs.uploadTalent.fileArray = [];
      (this.dataData17 = []), (this.errorMes = []);
    },

    // 头像上传
    filesPro(data) {
      let FileExt = data.map((item) => {
        return item.name.replace(/.+\./, "");
      });
      let flag = FileExt.map((item) => {
        return ["jpg", "jpeg", "png"].includes(item);
      });
      let errorMe = [];
      flag.forEach((item, index) => {
        if (!item) {
          errorMe.push(index);
        }
      });
      this.dataData18 = data;
      this.errorMes = errorMe.map((item) => {
        return (item = item + 1);
      });
    },
    deleteTalentsData1(data, index1) {
      this.dataData18.splice(index1, 1);
      for (let i = 0; i < this.errorMes.length; i++) {
        if (this.errorMes[i] == index1 + 1) {
          for (let j = 0; j < this.errorMes.length; j++) {
            if (this.errorMes[j] > this.errorMes[i]) {
              this.errorMes[j] = this.errorMes[j] - 1;
            }
          }
          this.errorMes.splice(i, 1);
        }
      }
    },
    async uploadTalents1() {
      if (this.errorMes.length > 0) {
        const errorMes1 = this.errorMes.join(",");
        this.$confirm(
          "第" + errorMes1.substring(0, 300) + "条数据格式错误",
          "提示"
        );
      } else {
        if (this.dataData18.length <= 100) {
          const form = new FormData();
          this.dataData18.forEach((item, index) => {
            form.append("files", this.dataData18[index]);
          });
          this.loading = true;
          const res = await uploadImportTalentPro(form);
          if (res.code == 200) {
            this.$confirm(res.msg, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout2",
            });
            this.loading = false;
          } else {
            const errorData = res.errorMsg.join(",");
            this.$confirm(errorData, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout2",
            });
            this.loading = false;
          }
          this.getTalent();
          this.dialogVisibleTalent1 = false;
        } else {
          this.$message.error("上传文件个数小于100");
        }
      }
    },
    handleCloseTalent1() {
      this.$refs.uploadTalent1.fileArray = [];
      (this.dataData18 = []), (this.errorMes = []);
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "personDataIntegrity") {
        this.queryInfo.columnName = "person_data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,update_time";
        }
      }
      this.getTalent();
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "技术类别") {
        return "cursor:pointer";
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  .upload_time {
    position: relative;
    color: #1f212b;
    font-size: 14px;
    margin-left: 20px;
    .el-icon-caret-top,
    .el-icon-caret-bottom {
      position: absolute;
      right: -16px;
      color: #1f212b;
    }
    .el-icon-caret-top {
      top: 0px;
    }
    .el-icon-caret-bottom {
      top: 6px;
    }
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.caretTime {
  cursor: pointer;
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>

<style lang="less">
.message-logout2 {
  .el-message-box__message p {
    margin: 0;
    line-height: 24px;
    max-height: 300px !important;
    overflow: auto;
  }
  .el-message-box__container {
    max-height: 300px !important;
    overflow: auto;
  }
}
</style>
