<template>
  <div class="openClose">
    <div class="openInfo" :class="{ newClass: isShow }">
      <el-radio-group v-model="value" @change="changeVal">
        <el-radio-button label>不限</el-radio-button>
        <el-radio-button
          v-for="(item, index) in options"
          :key="index + item.codeName"
          :label="item.codeName"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="openButton" @click="openItem()" v-if="options.length > 10">
      {{ openSpan }}
      <i :class="openicon"></i>
    </div>
  </div>
</template>

<script>
import { getDicRadioButton } from "@/api/common";
import { getPeriodicalTag } from "@/api/paper";
export default {
  props: {
    typeCode: {
      // 查询的字典值
      type: String,
      default: "",
    },
    code: {
      // 选中的那一项的key
      type: String,
      default: "",
      require: true,
    },
    queryName: {
      // el-tags做标识
      type: String,
    },
    name: {
      // el-tags选中的文字
      type: String,
    },
  },
  data() {
    return {
      options: [],
      value: "",
      openSpan: "展开全部",
      openicon: "el-icon-arrow-down",
      openValue: true,
      isShow: false,
    };
  },
  watch: {
    code: function (newVal) {
      this.value = newVal;
    },
    value: function (newVal) {
      this.$emit("update:code", newVal);
    },
  },
  created() {
    this.getDicData();
  },
  methods: {
    async getDicData() {
      if (this.name === "会议/期刊") {
        const res = await getPeriodicalTag();

        if (res.code === 200) {
          this.options = res.data;

          this.options = this.options.map((item) => {
            return {
              codeName: item.conference,
            };
          });
        }
      } else {
        this.options = sessionStorage.getItem(this.typeCode);

        if (!this.options) {
          const { data: res } = await getDicRadioButton({
            code: this.typeCode,
          });

          sessionStorage.setItem(this.typeCode, JSON.stringify(res));
        }

        this.options = JSON.parse(sessionStorage.getItem(this.typeCode));
      }

      if (this.code) {
        this.options.forEach((item) => {
          if (item.codeName === this.code) {
            this.value = item.codeName;
          }
        });
      }
    },
    // 标签打开
    openItem() {
      if (this.openValue) {
        this.isShow = true;
        // this.openSpan = '点击关闭'
        this.openicon = "el-icon-arrow-up";
        this.openValue = false;
      } else {
        this.isShow = false;
        // this.openSpan = '点击展开'
        this.openValue = true;
        this.openicon = "el-icon-arrow-down";
      }
    },
    changeVal(val) {
      this.$emit("onChange", val, this.queryName, this.name);
    },
  },
};
</script>

<style lang="less" scoped>
.openClose {
  display: flex;
  justify-content: space-between;
  .openButton {
    width: 10%;
    line-height: 30px;
    color: #1e9fff;
    cursor: pointer;
    text-align: center;
  }
}
.openInfo {
  width: 90%;
  max-height: 40px;
  overflow: hidden;
  // transition: all 1s linear;
}
.newClass {
  width: 90%;
  max-height: 400px;
  transition: all 1s linear;
}
</style>
