<template>
  <div>
    <el-upload
      class="upload-demo"
      action
      :on-remove="handleRemove"
      multiple
      accept="['jpg', 'png', 'jpeg']"
      :show-file-list="isShowList"
      :file-list="loadFileList"
      :before-upload="beforeUpload"
      :http-request="upload"
    >
      <el-button type="primary">+上传头像</el-button>
    </el-upload>
  </div>
</template>
<script>
import { uploadImportTalentPro } from '@/api/common'
export default {
  props: {
    loadFileList: {
      type: Array,
    },
    isShowList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileArray: [],
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  methods: {
    handleRemove(file, fileList) {},

    async upload(file) {
      // this.fileArray.push(file.file)

      this.fileArray.push(file.file)


      this.$emit('filesPro', this.fileArray)
      // this.fileArray.forEach((item, index) => {
      //   form.append('files', this.fileArray[index])
      // })


      // form.append('files', file.file)

      // form.append('Authorization', this.Authorization)

      // const res = await uploadImportTalentPro(form)

      // if (res.code === 415 || res.code === 200) {
      //   this.$confirm(res.msg, '提示')
      //     .then(() => {})
      //     .catch(() => {})
      // }
    },

    beforeUpload(file) {
      // let FileExt = file.name.replace(/.+\./, '')
      // let flag = ['jpg', 'png','jpeg'].includes(FileExt)
      // const isLt5M = file.size / 1024 / 1024 < 5
      // if (!flag) this.$message.error('文件格式错误请重新上传!')
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      // return flag && isLt5M
    },
  },
}
</script>
<style lang="less" scoped>
.iconjr-icon-link {
  font-size: 12px;
}
.the-active {
  .el-button {
    color: #4da75b !important;
  }
  i {
    color: #4da75b !important;
  }
}
</style>
