<template>
  <div>
    <el-upload
      class="upload-demo"
      action
      :on-remove="handleRemove"
      multiple
      :show-file-list="isShowList"
      :file-list="loadFileList"
      :before-upload="beforeUpload"
      :http-request="upload"
    >
      <el-button type="primary" icon="jr-iconjr-icon-upload">数据上传</el-button>
    </el-upload>
  </div>
</template>
<script>
import { uploadImportCompany } from '../api/common'
export default {
  props: {
    loadFileList: {
      type: Array,
    },
    isShowList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileArray: [],
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  methods: {
    handleRemove(file, fileList) {
      // this.$emit('removeFile', file)
    },

    async upload(file) {
      const form = new FormData()
      this.fileArray.push(file.file)
      this.$emit('filesDo', this.fileArray)
    },

    beforeUpload(file) {
      // let FileExt = file.name.replace(/.+\./, '')
      // let flag = ['doc', 'docx', 'xls', 'xlsx'].includes(FileExt)
      // const isLt5M = file.size / 1024 / 1024 < 5
      // if (!flag) this.$message.error('文件格式错误请重新上传!')
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      // return flag && isLt5M
    },
  },
}
</script>
<style lang="less" scoped>
.iconjr-icon-link {
  font-size: 12px;
}
.the-active {
  .el-button {
    color: #4da75b !important;
  }
  i {
    color: #4da75b !important;
  }
}
</style>
